import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ResourceTableComponent} from './resource-table/resource-table.component';
import {AngularMaterialModule} from '../../../angular-material.module';
import {ResourceFormComponent} from './resource-form/resource-form.component';
import {SpinnerModule} from '../components/spinner/spinner.module';
import {ResourceTreeComponent} from './resource-tree/resource-tree.component';
import {MatTreeModule} from '@angular/material';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [
    ResourceFormComponent,
    ResourceTableComponent,
    ResourceTreeComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    MatTreeModule,
    SpinnerModule,
    RouterModule
  ],
  exports: [
    ResourceFormComponent,
    ResourceTableComponent,
    ResourceTreeComponent
  ]
})
export class ResourceModule {
}
