import {SiteLanguage} from './site-language';

export class Component {
  id: number;
  name: string;
  content: any;
  enabled: boolean;
  siteLanguage: SiteLanguage;
  createdAt: Date;
  updatedAt: Date;
}
