import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericResponse, RestService} from 'ngx-restful';
import {environment} from '../../../environments/environment';
import {Course} from '../models';
import {Observable} from 'rxjs';

@Injectable()
export class CourseService extends RestService<Course, GenericResponse> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.baseUrl + 'courses';
  }

  public publishCourse(id: number): Observable<GenericResponse> {
    return this.post(this.getBaseUrlPath() + '/publish/' + id, {});
  }

  public unpublishCourse(id: number): Observable<GenericResponse> {
    return this.post(this.getBaseUrlPath() + '/unpublish/' + id, {});
  }

  public cloneCourse(id: number, newName: string): Observable<GenericResponse> {
    return this.post(this.getBaseUrlPath() + '/clone/' + id, {name: newName});
  }
}
