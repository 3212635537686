import { Injectable } from "@angular/core";
import { PAGE_COMPONENTS } from "../enums/page-components";
import { PAGE_TYPES } from "../enums/page-types";
import { CATEGORY_TYPES } from "../enums/category-types";

@Injectable()
export class ModuleUtil {
  public getConfig(categoryType: string, pageType: string = null): any {
    if (pageType) {
      pageType = pageType.toUpperCase();
    }
    if (!categoryType || pageType === PAGE_TYPES.STATIC) {
      return this.getStaticConfig();
    }
    categoryType = categoryType.toUpperCase();
    if (
      categoryType === CATEGORY_TYPES.NEWS &&
      pageType === PAGE_TYPES.ARTICLE
    ) {
      return this.getArticleConfig();
    }
    if (categoryType === CATEGORY_TYPES.NEWS && pageType === PAGE_TYPES.VIDEO) {
      return this.getVideoConfig();
    }
    if (
      categoryType === CATEGORY_TYPES.NEWS &&
      pageType === PAGE_TYPES.BROCHURE
    ) {
      return this.getBrochureConfig();
    }
    if (
      categoryType === CATEGORY_TYPES.RECIPES &&
      pageType === PAGE_TYPES.RECIPE
    ) {
      return this.getRecipeConfig();
    }

    return null;
  }

  public getArticleConfig(): ModuleConfig {
    return {
      componentTypes: [PAGE_COMPONENTS.IMAGE, PAGE_COMPONENTS.PARAGRAPH],
      excludedComponentTypes: [PAGE_COMPONENTS.RECIPE],
      allowedPageTypes: [PAGE_TYPES.ARTICLE],
    };
  }

  public getVideoConfig(): ModuleConfig {
    return {
      componentTypes: [
        PAGE_COMPONENTS.YOUTUBE_VIDEO,
        PAGE_COMPONENTS.PARAGRAPH,
      ],
      excludedComponentTypes: [PAGE_COMPONENTS.RECIPE],
      allowedPageTypes: [PAGE_TYPES.VIDEO],
    };
  }

  public getBrochureConfig(): ModuleConfig {
    return {
      componentTypes: [
        PAGE_COMPONENTS.FILE,
        PAGE_COMPONENTS.IMAGE,
        PAGE_COMPONENTS.PARAGRAPH,
      ],
      allowedPageTypes: [PAGE_TYPES.BROCHURE],
    };
  }

  public getRecipeConfig(): ModuleConfig {
    return {
      componentTypes: [PAGE_COMPONENTS.RECIPE],
      allowedPageTypes: [PAGE_TYPES.RECIPE],
    };
  }

  public getStaticConfig(): ModuleConfig {
    return {
      excludedComponentTypes: [PAGE_COMPONENTS.RECIPE],
      excludePageFields: ["scheduledAt", "featured"],
    };
  }
}

export interface ModuleConfig {
  componentTypes?: Array<string>;
  allowedComponentTypes?: Array<string>;
  excludedComponentTypes?: Array<string>;
  allowedPageTypes?: Array<string>;
  excludedPageTypes?: Array<string>;
  excludePageFields?: Array<string>;
}
