import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {
  HomeComponent,
  NotFoundComponent
} from './pages';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'users',
    loadChildren: './pages/users/users.module#UsersModule',
  },
  {
    path: 'tags',
    loadChildren: './pages/tags/tags.module#TagsModule',
  },
  {
    path: 'categories',
    loadChildren: './pages/categories/categories.module#CategoriesModule',
  },
  {
    path: 'collections',
    loadChildren: './pages/collections/collections.module#CollectionsModule',
  },
  {
    path: 'pages',
    loadChildren: './pages/pages/pages.module#PagesModule',
  },
  {
    path: 'news',
    loadChildren: './pages/pages/pages.module#PagesModule',
  },
  {
    path: 'recipes',
    loadChildren: './pages/pages/pages.module#PagesModule',
  },
  {
    path: 'quizzes',
    loadChildren: './pages/quizzes/quizzes.module#QuizzesModule',
  },
  {
    path: 'courses',
    loadChildren: './pages/courses/courses.module#CoursesModule',
  },
  {
    path: 'cases',
    loadChildren: './pages/cases/cases.module#CasesModule',
  },
  {
    path: 'login',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
