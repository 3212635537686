import {SiteLanguage} from './site-language';

export class PageIntl {
  id: number;
  title: string;
  description: string;
  urlSlug: string;
  schemaJsonLd: any;
  data: any;
  enabled: boolean;
  siteLanguage: SiteLanguage;
  createdAt: Date;
  updatedAt: Date;
}
