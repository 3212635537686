import {Category} from './category';
import {Tag} from './tag';
import {MedicalCaseIntl} from './medical-case-intl';

export class MedicalCase {
  id: number;
  name: string;
  category: Category;
  mainImage: string;
  tags: Array<Tag> = [];
  medicalCasesIntl: Array<MedicalCaseIntl> = [];
  featured: boolean;
  enabled: boolean;
  scheduledAt: Date;
  publishedAt: Date;
  passRequirements: number;
  points: number;
  createdAt: Date;
  updatedAt: Date;
}
