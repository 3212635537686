import {CourseElementIntl} from './course-element-intl';

export class CourseElement {
  id: number;
  name: string;
  order: number;
  type: string;
  enabled: boolean;
  required: boolean;
  courseElementsIntl: Array<CourseElementIntl> = [];
  createdAt: Date;
  updatedAt: Date;
}
