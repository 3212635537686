import {Question} from './question';

export class QuizQuestion {
  id: number;
  name: string;
  order: number;
  type: string;
  page: number;
  enabled: boolean;
  quizQuestionsIntl: Array<Question> = [];
  createdAt: Date;
  updatedAt: Date;
}
