import {SiteLanguage} from './site-language';

export class TagIntl {
  id: number;
  name: string;
  enabled: boolean;
  siteLanguage: SiteLanguage;
  createdAt: Date;
  updatedAt: Date;
}
