import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericResponse, RestService} from 'ngx-restful';
import {environment} from '../../../environments/environment';
import {Page} from '../models';
import {Observable} from 'rxjs';

@Injectable()
export class PageService extends RestService<Page, GenericResponse> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.apiBase + 'pages';
  }

  public publishPage(id: number): Observable<GenericResponse> {
    return this.post(this.getBaseUrlPath() + '/publish/' + id, {});
  }

  public unpublishPage(id: number): Observable<GenericResponse> {
    return this.post(this.getBaseUrlPath() + '/unpublish/' + id, {});
  }
}
