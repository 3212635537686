import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericResponse, RestService} from 'ngx-restful';
import {environment} from '../../../environments/environment';
import {Category} from '../models';
import {Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class CategoryService extends RestService<Category, GenericResponse> {

  categorySubject: Subject<any> = new Subject<any>();

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.apiBase + 'categories';
  }

  createOne(model: Category, options?: Object, path?: string): Observable<GenericResponse> {
    return super.createOne(model, options, path).pipe(tap(c => this.categorySubject.next({})));
  }

  updateOne(model: Category, options?: Object, path?: string): Observable<GenericResponse> {
    return super.updateOne(model, options, path).pipe(tap(c => this.categorySubject.next({})));
  }

  deleteOne(id: number, options?: Object, path?: string): Observable<GenericResponse> {
    return super.deleteOne(id, options, path).pipe(tap(c => this.categorySubject.next({})));
  }

  reorder(ordering: any): Observable<GenericResponse> {
    return this.put(this.getBaseUrlPath() + '/reorder', ordering);
  }

  public categoriesChanged(): Observable<any> {
    return this.categorySubject.asObservable();
  }
}
