import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Pipe({name: 'file'})
export class FilePipe implements PipeTransform {

  transform(value: string, type: string = 'image'): string {
    return environment.baseUrl + 'data/' + type + '/' + value;
  }
}
