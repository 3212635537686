import {Page, Site} from '.';

export class Collection {
  id: number;
  name: string;
  description: string;
  enabled: boolean;
  site: Site;
  pages: Array<Page> = [];
  createdAt: Date;
  updatedAt: Date;
}
