import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericResponse, RestService} from 'ngx-restful';
import {environment} from '../../../environments/environment';
import {MedicalCase} from '../models';
import {Observable} from 'rxjs';

@Injectable()
export class CaseService extends RestService<MedicalCase, GenericResponse> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.baseUrl + 'cases';
  }

  public publishCase(id: number): Observable<GenericResponse> {
    return this.post(this.getBaseUrlPath() + '/publish/' + id, {});
  }

  public unpublishCase(id: number): Observable<GenericResponse> {
    return this.post(this.getBaseUrlPath() + '/unpublish/' + id, {});
  }
}
