import {Site} from './site';
import {CategoryIntl} from './category-intl';

export class Category {
  id: number;
  name: string;
  type: string;
  order: number;
  mainImage: string;
  enabled: boolean;
  visible: boolean;
  site: Site;
  parentCategory: Category;
  subCategories: Array<Category> = [];
  categoriesIntl: Array<CategoryIntl> = [];
  createdAt: Date;
  updatedAt: Date;
}
