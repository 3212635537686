import {CourseElement} from './course-element';
import {CourseSectionIntl} from './course-section-intl';

export class CourseSection {
  id: number;
  name: string;
  order: number;
  elements: Array<CourseElement> = [];
  courseSectionsIntl: Array<CourseSectionIntl> = [];
  completionRequired: boolean;
  enabled: boolean;
  createdAt: Date;
  updatedAt: Date;
}
