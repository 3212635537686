import {Injectable} from '@angular/core';
import {Site, Tag} from '../models';
import {TagIntl} from '../models/tag-intl';
import {TagService} from '../services';

@Injectable()
export class TagUtil {

  constructor(private tagService: TagService) {
  }

  public addNewTag(tagInput: any, values: any, currentSite: Site, tagType: string): void {
    let tagName = tagInput.val();
    if (!tagName || tagName.trim() === '') {
      return;
    }
    let tags = values['options']['values'];
    let existingTag = tags.find(t => t.name.toLowerCase() === tagName.toLowerCase());
    if (existingTag) {
      if (!values['selectedValues']) {
        values['selectedValues'] = [];
      }
      if (!values['selectedValues'].find(sv => sv. name.toLowerCase() === tagName.toLowerCase())) {
        values['selectedValues'].push(existingTag.value);
      }
      tagInput.val('');
      return;
    }
    let newTag = new Tag();
    newTag.name = tagName;
    newTag.site = new Site();
    newTag.site.id = currentSite.id;
    newTag.type = tagType;
    newTag.enabled = true;
    currentSite.languages.forEach(lang => {
      let tagIntl = new TagIntl();
      tagIntl.siteLanguage = lang;
      tagIntl.name = newTag.name;
      tagIntl.enabled = true;
      newTag.tagsIntl.push(tagIntl);
    });
    if (!values['selectedValues']) {
      values['selectedValues'] = [];
    }
    values['selectedValues'].push(newTag);
    this.tagService.createOne(newTag).subscribe(resp => {
      newTag.id = +resp.data['id'];
      values['selectedValues'].find(t => t.name && t.name.toLowerCase() === newTag.name.toLowerCase()).id = newTag.id;
      values.options['values'].push({value: newTag, name: newTag.name});
    });
    tagInput.val('');
  }
}
