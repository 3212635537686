import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material";

@Component({
  selector: "app-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.css"],
})
export class ImageUploadComponent implements OnInit {
  @Input() location: string;

  @Input() data: any;
  @Output() dataChanged = new EventEmitter<any>();

  isLocalFile: boolean = false;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  uploadFile(event): void {
    const element = event[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const target: any = e.target;
      this.isLocalFile = true;
      this.data["imageData"] = target.result;
      this.dataChanged.emit(this.data);
    };
    reader.readAsDataURL(element);
  }

  deleteImage() {
    this.data["imageData"] = null;
    this.dataChanged.emit(this.data);
  }

  isImageBase64(imageData: string): boolean {
    if (!imageData) {
      return true;
    }
    return imageData.indexOf("data:") === 0;
  }
}
