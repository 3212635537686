import {Site} from './site';

export class Sponsor {
  id: number;
  name: string;
  description: string;
  mainImage: string;
  websiteLink: string;
  enabled: boolean;
  site: Site;
  createdAt: Date;
  updatedAt: Date;
}
