import {Category} from '../models';
import {Injectable} from '@angular/core';

@Injectable()
export class CategoryUtil {

  public generateHierarchy(categories: Category[], ignoreId: number, maxLevel: number = 3, level: number = 0):
    Array<{ value: Category, name: string }> {
    let values = [];
    if (level === maxLevel) {
      return values;
    }
    let prefix = '';
    for (let i = 0; i < level; i++) {
      if (i + 1 === level) {
        prefix += '-> ';
      } else {
        prefix += '-';
      }
    }
    categories.forEach(category => {
      let name = prefix + category.name;
      if (category.id !== ignoreId) {
        values.push({value: category, name: name});
      }
      if (category.subCategories && category.subCategories.length > 0 && category.id !== ignoreId) {
        let subCategs = this.generateHierarchy(category.subCategories, ignoreId, maxLevel, level + 1);
        values.push(...subCategs.filter(sc => sc.value.id !== ignoreId));
      }
    });
    return values;
  }
}
