import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import {AuthModule, ResourceModule, SpinnerModule} from './shared/modules';
import {AppRoutingModule} from './app-routing.module';
import {AngularMaterialModule} from './angular-material.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

import {BreadcrumbsComponent, CategoryTreeComponent, ConfirmDialogComponent} from './components';

import {HomeComponent, LoginComponent, NotFoundComponent} from './pages';

import {
  CaseService,
  CategoryService,
  CollectionService,
  CourseElementService,
  CourseSectionService,
  CourseService, LecturerService,
  PageComponentService,
  PageService,
  QuizQuestionService,
  QuizService,
  SiteService,
  SponsorService,
  TagService,
  UserService
} from './shared/services';

import {CategoryUtil, ModuleUtil, TagUtil, ResourceUtil, TranslateFileLoader} from './shared/util';
import {ResizableModule} from 'angular-resizable-element';
import {DragDropModule} from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    HomeComponent,
    ConfirmDialogComponent,
    BreadcrumbsComponent,
    CategoryTreeComponent
  ],
  imports: [
    AngularMaterialModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    DragDropModule,
    ResizableModule,
    ResourceModule,
    SpinnerModule,
    AuthModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateFileLoader
      }
    })
  ],
  providers: [
    SiteService,
    CategoryService,
    CollectionService,
    PageService,
    PageComponentService,
    TagService,
    UserService,
    QuizService,
    QuizQuestionService,
    CourseService,
    CourseSectionService,
    CourseElementService,
    SponsorService,
    LecturerService,
    CaseService,
    CategoryUtil,
    TagUtil,
    ModuleUtil,
    ResourceUtil
  ],
  entryComponents: [
    ConfirmDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
