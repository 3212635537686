import {SiteLanguage} from './site-language';

export class CourseIntl {
  id: number;
  title: string;
  description: string;
  urlSlug: string;
  organizers: string;
  enabled: boolean;
  siteLanguage: SiteLanguage;
  createdAt: Date;
  updatedAt: Date;
}
