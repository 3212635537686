import {SiteLanguage} from './site-language';

export class MedicalCaseIntl {
  id: number;
  title: string;
  description: string;
  urlSlug: string;
  enabled: boolean;
  personalData: any;
  motivationData: any;
  talkData: any;
  diagnosticData: any;
  diagnosisData: any;
  therapyData: any;
  conclusion: any;
  siteLanguage: SiteLanguage;
  createdAt: Date;
  updatedAt: Date;
}
