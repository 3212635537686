import {Component} from './component';

export class PageComponent {
  id: number;
  name: string;
  order: number;
  columns: number;
  type: string;
  enabled: boolean;
  componentsIntl: Array<Component> = [];
  createdAt: Date;
  updatedAt: Date;
}
