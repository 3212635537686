import {Category} from './category';
import {CourseSection} from './course-section';
import {CourseIntl} from './course-intl';
import {Tag} from './tag';
import {Sponsor} from './sponsor';

export class Course {
  id: number;
  name: string;
  category: Category;
  mainImage: string;
  bannerImage: string;
  organizerImage: string;
  author: string;
  copyright: string;
  sections: Array<CourseSection> = [];
  coursesIntl: Array<CourseIntl> = [];
  tags: Array<Tag> = [];
  sponsors: Array<Sponsor> = [];
  exclusive: boolean;
  exitSurvey: boolean;
  generateCertificate: boolean;
  featured: boolean;
  enabled: boolean;
  comments: boolean;
  scheduledAt: Date;
  publishedAt: Date;
  startAt: Date;
  endAt: Date;
  passRequirements: number;
  points: number;
  createdAt: Date;
  updatedAt: Date;
}
