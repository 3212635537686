import {PageComponent} from './page-component';
import {Tag} from './tag';
import {PageIntl} from './page-intl';
import {Category} from './category';

export class Page {
  id: number;
  name: string;
  type: string;
  category: Category;
  mainImage: string;
  timeToRead: number;
  author: string;
  copyright: string;
  components: Array<PageComponent> = [];
  pagesIntl: Array<PageIntl> = [];
  connectedPages: Array<Page> = [];
  tags: Array<Tag> = [];
  featured: boolean;
  enabled: boolean;
  scheduledAt: Date;
  publishedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}
