import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericResponse, RestService} from 'ngx-restful';
import {environment} from '../../../environments/environment';
import {Site} from '../models';
import {Observable, Subject} from 'rxjs';
import {SiteLanguage} from '../models/site-language';

@Injectable()
export class SiteService extends RestService<Site, GenericResponse> {
  currentSite: Site;
  siteSubject: Subject<Site> = new Subject<Site>();

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.apiBase + 'sites';
  }

  public getCurrentSiteId(): number {
    return +localStorage.getItem('SITE_ID');
  }

  public getCurrentSite(): Site {
    if (this.currentSite) {
      return this.currentSite;
    } else {
      return new Site();
    }
  }

  public setCurrentSite(site: Site): void {
    if (site) {
      localStorage.setItem('SITE_ID', site.id.toString());
    }
    this.currentSite = site;
    let hrLang: SiteLanguage = this.currentSite.languages.find(l => l.languageCode === 'HR');
    if (hrLang) {
      this.currentSite.languages = this.currentSite.languages.filter(l => l.languageCode !== 'HR');
      this.currentSite.languages.unshift(hrLang);
    }
    this.siteSubject.next(site);
  }

  public siteChanged(): Observable<Site> {
    return this.siteSubject.asObservable();
  }
}
