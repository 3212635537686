import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericResponse, RestService} from 'ngx-restful';
import {environment} from '../../../environments/environment';
import {Lecturer} from '../models';

@Injectable()
export class LecturerService extends RestService<Lecturer, GenericResponse> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.baseUrl + 'lecturers';
  }
}
