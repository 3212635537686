import {Site} from './site';
import {TagIntl} from './tag-intl';

export class Tag {
  id: number;
  name: string;
  type: string;
  enabled: boolean;
  site: Site;
  tagsIntl: Array<TagIntl> = [];
  createdAt: Date;
  updatedAt: Date;
}
