import {Category} from './category';
import {QuizQuestion} from './quiz-question';
import {QuizIntl} from './quiz-intl';
import {Tag} from './tag';

export class Quiz {
  id: number;
  name: string;
  category: Category;
  mainImage: string;
  enabled: boolean;
  questions: Array<QuizQuestion> = [];
  quizzesIntl: Array<QuizIntl> = [];
  tags: Array<Tag> = [];
  scheduledAt: Date;
  publishedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}
