import {SiteLanguage} from './site-language';

export class Question {
  id: number;
  content: any;
  rules: any;
  siteLanguage: SiteLanguage;
  createdAt: Date;
  updatedAt: Date;
}
