import {Injectable} from '@angular/core';

@Injectable()
export class ResourceUtil {

  public generateUrlSlug(title: string): string {
    if (!title) {
      return null;
    }

    title = title.replace(/^\s+|\s+$/g, '').toLowerCase();

    // remove accents, swap ñ for n, etc
    let from = 'ãàáäâẽèéëêìíïîđõòóöôžšùúüûñčćç·/_,:;';
    let to = 'aaaaaeeeeeiiiidooooozsuuuunccc------';
    for (let i = 0, l = from.length; i < l; i++) {
      title = title.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    title = title.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return title;
  }
}
