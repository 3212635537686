import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GenericResponse, RestService} from 'ngx-restful';
import {CourseSection} from '../models';
import {environment} from '../../../environments/environment';

@Injectable()
export class CourseSectionService extends RestService<CourseSection, GenericResponse> {

  constructor(protected http: HttpClient) {
    super(http);
  }

  getBaseUrlPath(): string {
    return environment.baseUrl + 'sections';
  }
}
