import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CategoryService, SiteService} from '../../shared/services';
import {ResourceTreeComponent, TreeConfig, TreeEvent, TreeNode} from '../../shared/modules/resource/resource-tree/resource-tree.component';
import {Router} from '@angular/router';
import {MatDialog, MatMenuTrigger, MatSnackBar} from '@angular/material';
import {Category} from '../../shared/models';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CATEGORY_TYPES} from '../../shared/enums/category-types';

@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrls: ['./category-tree.component.css']
})
export class CategoryTreeComponent implements OnInit, OnDestroy {
  CATEGORY_TYPES = CATEGORY_TYPES;
  selectedCategory: Category;
  selectedLevel: number;

  private unsubscribe: Subject<void> = new Subject();

  @ViewChild(ResourceTreeComponent) treeComponent: ResourceTreeComponent;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  treeConfig: TreeConfig = {
    name: 'subCategories',
    childrenName: ['subCategories'],
    parentName: 'parentCategory',
    showHeader: false,
    icons: [
      {name: 'subCategories', closedIcon: 'folder', openIcon: 'folder_open', customFunc: this.selectIcon}
    ],
    sorts: [
      {name: 'subCategories', sortFunc: (c1, c2) => c1.order - c2.order}
    ]
  };

  constructor(public siteService: SiteService,
              public categoryService: CategoryService,
              private dialog: MatDialog,
              private router: Router,
              private translateService: TranslateService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.siteService.siteChanged().pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.treeComponent.reloadTree();
    });
    this.categoryService.categoriesChanged().pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.treeComponent.reloadTree();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  openMenu(event: TreeEvent): void {
    if (event) {
      event.data.event.preventDefault();
    }
    this.selectedCategory = event.data.value;
    this.selectedLevel = event.data.level;
    let x = event.data.event.x;
    let y = event.data.event.y;
    let menu = document.getElementById('menuTrigger');
    menu.style.display = '';
    menu.style.position = 'fixed';
    menu.style.left = x + 'px';
    menu.style.top = y + 'px';
    this.menuTrigger.openMenu();
  }

  onMenuClosed(): void {
    let menu = document.getElementById('menuTrigger');
    if (menu) {
      menu.style.display = 'none';
    }
  }

  goToView(): void {
    let moduleType = this.selectedCategory.type ? this.selectedCategory.type.toLowerCase() : 'pages';
    let url = this.selectedCategory.type || this.selectedCategory.subCategories.length === 0 ? '/' + moduleType : '/categories';
    this.router.navigate([url], {queryParams: {categoryId: this.selectedCategory.id}});
  }

  goToEdit(): void {
    this.router.navigate(['/categories/edit/', this.selectedCategory.id]);
  }

  goToCreateCategory(type: string): void {
    this.router.navigate(['/categories/create'], {queryParams: {type: type, categoryId: this.selectedCategory.id}});
  }

  goToCreatePage(subtype: string = null): void {
    let moduleType = this.selectedCategory.type ? this.selectedCategory.type.toLowerCase() : 'pages';
    let params = {categoryId: this.selectedCategory.id};
    if (subtype != null) {
      params['type'] = moduleType;
      params['subtype'] = subtype;
    }
    this.router.navigate(['/' + moduleType + '/create'], {queryParams: params});
  }

  move(direction: string): void {
    let parentNode = this.findParentNode(this.selectedCategory.id, this.treeComponent.getData());
    if (parentNode != null) {
      this.updateOrdering(this.selectedCategory.id, parentNode.children, parentNode, direction);
    } else {
      this.updateOrdering(this.selectedCategory.id, this.treeComponent.getData(), null, direction);
    }
  }

  openDeleteDialog(): void {
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: true, autoFocus: false, width: '400px',
      data: {
        title: 'categories.dialog.deleteTitle',
        text: 'categories.dialog.deleteQuestion',
        textArgs: {name: this.selectedCategory.name},
        buttons: [{name: 'delete', text: 'common.delete', color: 'warn'}]
      }
    }).afterClosed().subscribe(name => {
      if (name === 'delete') {
        this.categoryService.deleteOne(this.selectedCategory.id).subscribe(resp => {
          this.translateService.get('categories.dialog.deletedText', {name: this.selectedCategory.name})
            .subscribe(text => {
              this.snackBar.open(text);
            });
        });
      }
    });
  }

  private selectIcon(category: Category): string {
    switch (category.type) {
      case CATEGORY_TYPES.NEWS:
        return 'view_comfy';
      case CATEGORY_TYPES.RECIPES:
        return 'restaurant';
      case CATEGORY_TYPES.QUIZZES:
        return 'assignment_turned_in';
      case CATEGORY_TYPES.COURSES:
        return 'school';
      case CATEGORY_TYPES.CASES:
        return 'emoji_objects';
      default:
        if (category.visible === false) {
          return 'visibility_off';
        }
        return null;
    }
  }

  private findParentNode(categoryId: number, treeData: Array<TreeNode>): TreeNode {
    let parent = null;
    for (let i = 0; i < treeData.length; i++) {
      parent = treeData[i];
      if (parent.data.id === categoryId) {
        return null;
      }
      if (parent.children && parent.children.length > 0) {
        let child = parent.children.find(c => c.data.id === categoryId && c.type === 'subCategories');
        if (child) {
          return parent;
        }
        let subParent = this.findParentNode(categoryId, parent.children);
        if (subParent != null) {
          return subParent;
        }
      }
    }
    return null;
  }

  private updateOrdering(categoryId: number, nodes: Array<TreeNode>, parentNode: TreeNode, direction: string): void {
    if (nodes == null || nodes.length < 2) {
      return;
    }
    let toMoveIndex = nodes.findIndex(n => n.data.id === categoryId && n.type === 'subCategories');
    if (toMoveIndex > -1) {
      let item = nodes.splice(toMoveIndex, 1);
      if (item.length !== 1) {
        return;
      }
      if (direction === 'top') {
        nodes.unshift(item[0]);
      } else if (direction === 'bottom') {
        nodes.push(item[0]);
      } else if (direction === 'up') {
        if (toMoveIndex === 0) {
          nodes.unshift(item[0]);
        } else {
          nodes.splice(toMoveIndex - 1, 0, item[0]);
        }
      } else if (direction === 'down') {
        if (toMoveIndex >= nodes.length) {
          nodes.push(item[0]);
        } else {
          nodes.splice(toMoveIndex + 1, 0, item[0]);
        }
      }
    }
    this.treeComponent.refreshTree();
    let ordering = {};
    let categories = nodes.map(n => n.data);
    categories.forEach((category, index) => {
      ordering[category.id] = index + 1;
    });
    this.categoryService.reorder(ordering).subscribe(() => {
    });
  }
}
