export enum PAGE_COMPONENTS {
  PARAGRAPH = 'PARAGRAPH',
  IMAGE = 'IMAGE',
  GALLERY = 'GALLERY',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
  YOUTUBE_VIDEO_GALLERY = 'YOUTUBE_VIDEO_GALLERY',
  LINK = 'LINK',
  FILE = 'FILE',
  RECIPE = 'RECIPE',
  QUOTE = 'QUOTE'
}
